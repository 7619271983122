import { TranslateService } from '@ngx-translate/core'
import { User } from 'src/app/models/user.model'
import { Injectable } from '@angular/core'
import { Video } from '../models/video.models'
import { SettingsService } from './settings.service'
import { Poi } from '../models/poi.models'
import { SocialSharing } from '@ionic-native/social-sharing/ngx'
import { ToastController } from '@ionic/angular'
import { HttpClient } from '@angular/common/http'
import { AngularFireDatabase } from '@angular/fire/database'

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  private baseUrl: string
  private apiUrl: string

  constructor(
    protected readonly http: HttpClient,
    private readonly settingsService: SettingsService,
    private readonly socialSharing: SocialSharing,
    private readonly toastController: ToastController,
    private readonly translateService: TranslateService,
    private readonly db: AngularFireDatabase,
  ) {
    this.baseUrl = this.settingsService.getString('webAppBaseUrl')
    this.apiUrl = this.settingsService.getString('baseUrl')
  }

  private getShareLinkFor(item: Video | Poi, type: string) {
    const link = `${this.baseUrl}/tabs/${type}/${item.id}`

    const title = item.title ? encodeURIComponent(item.title) : ''
    const description = item.description ? encodeURIComponent(item.description) : ''
    const image = item.cover ? encodeURIComponent(item.cover) : ''

    const url = this.getShareLinkWith(link, title, description, image)

    return url
  }

  private getProfileShareLinkFor(user: User) {
    const link = encodeURIComponent(`${this.baseUrl}/profile/${user.id}`)
    const title = encodeURIComponent(`${user.name} ${user.surname}`)
    const image = user.avatar ? encodeURIComponent(user.avatar) : ''

    const url = this.getShareLinkWith(link, title, '', image)

    return url
  }

  private getShareLinkWith(
    link: string,
    title?: string,
    description?: string,
    imageUrl?: string, // The image should be at least 300x200 px, and less than 300 KB
    force_install: boolean = true
  ) {
    const androidPackageName = this.settingsService.getString('androidPackageName')
    const iosBundleId = this.settingsService.getString('iosBundleId')
    const iosAppStoreId = this.settingsService.getString('iosAppStoreId')
    const domain = this.settingsService.getString('firebaseDynamicLinkDomain')

    const fallback_link = !force_install ? `&afl=${link}&ifl=${link}` : ''
    const st = title ? `&st=${title}` : ''
    const sd = description ? `&sd=${description}` : ''
    const si = imageUrl ? `&si=${imageUrl}` : ''

    // tslint:disable-next-line: max-line-length
    const url = `https://${domain}/?link=${link}&apn=${androidPackageName}${fallback_link}&ibi=${iosBundleId}&isi=${iosAppStoreId}${st}${sd}${si}`
    return url
  }

  shareItem(item: Video | Poi, type: string) {

    const url = this.getShareLinkFor(item, type)

    this.share(url, item.title, item.description)
  }

  shareProfile(user: User) {
    const url = this.getProfileShareLinkFor(user)

    this.share(url, `${user.name} ${user.surname}`, '')
  }

  shareApp() {
    const url = `https://camperinfinity.page.link/app`

    this.socialSharing.share('', 'Camper INFINITY', '', url)
    .then(() => {
      console.log('Success!')
    }).catch(() => {
      console.log('Error!')
      this.copyToClipBoard(url)
    })
  }

  private share(url: string, title: string, description: string) {
    const apiUrl = `${this.apiUrl}/api/share-link/shortener/`
    this.http.post(apiUrl, {'url': url}).subscribe(
      (shorterUrl: string) => {
        const path = (new URL(shorterUrl)).pathname
        if (path) {
          const link = (new URL(url)).searchParams.get('link')
          this.db.object<string>(`links${path}`).set(link)
        }
        this.socialSharing.share(description, title, '', shorterUrl)
        .then(() => {
          console.log('Success!')
        }).catch(() => {
          console.log('Error!')
          this.copyToClipBoard(shorterUrl)
        })
      }
    )
  }

  copyToClipBoard(text: string) {
    const i = document.createElement('input')
    i.value = text
    const body = document.getElementsByTagName('body')[0]
    body.appendChild(i)
    i.select()
    const copy = document.execCommand('copy')
    console.log('COPY:', copy)
    body.removeChild(i)
    this.toastController.create({
      message: this.translateService.instant('Copiato negli appunti.'),
      duration: 2000
    }).then(toast => {
      toast.present()
    })
  }
}
