import { Ad } from './../models/ads.models'
import { SettingsService } from './settings.service'
import { Observable, throwError } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AdsService {
  baseURL: string

  constructor(private readonly http: HttpClient, private readonly settings: SettingsService) {
    this.baseURL = this.settings.getString('baseUrl')
  }

  getAd(): Observable<Ad> {
    const url = `${this.baseURL}/api/ads/`
    return this.http.get<Ad[]>(url).pipe(
      map(ads => {
        if (!ads[0]) {
          return
        }

        return ads[0]
      })
    )
  }
}
