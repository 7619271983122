import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Level } from '../models/user.model'
import { SettingsService } from './settings.service'
import { from } from 'rxjs'
import { map, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LevelService {
  private levels$: Promise<Array<Level>>
  private levelsMap: Map<number, Level> = new Map()

  constructor(
    private readonly http: HttpClient,
    private readonly settingsService: SettingsService
  ) { }


  getLevel(id: number): Promise<Level> {
    if (!this.levels$) {
      this.levels$ = this.getLevels()
    }

    return from(this.levels$).pipe(
      map(() => this.levelsMap.get(id))
    ).toPromise()
  }

  getLevels(): Promise<Array<Level>> {

    const baseUrl: string = this.settingsService.getSetting('baseUrl')
    const url = `${baseUrl}/api/levels/`

    return this.http.get<Array<Level>>(url).pipe(
      tap(levels => {
        levels.forEach(level => {
          this.levelsMap.set(level.id, level)
        })
      })
    ).toPromise()
  }
}
