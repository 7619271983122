import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'

import { CheckList } from './../models/checklist.models'
import { ApiPageManager } from './api-page-manager'
import { SettingsService } from './settings.service'

@Injectable({
  providedIn: 'root'
})
export class CheckListService extends ApiPageManager<CheckList> {
  baseURL: string
  private checklists: Array<CheckList> = []
  private isModified$: BehaviorSubject<boolean>


  constructor(protected readonly http: HttpClient, private readonly settings: SettingsService) {
    super(http)
    this.baseURL = this.settings.getString('baseUrl')
    this.isModified$ = new BehaviorSubject<boolean>(null)
  }

  getIsModified(): BehaviorSubject<boolean> {
    return this.isModified$
  }

  setIsModified(value: boolean): void {
    this.isModified$.next(value)
  }

  getCheckLists(ownerId?: number): Observable<CheckList[]> {
    const url = `${this.baseURL}/api/checklists/`
    const params = ownerId ? { owner_id: `${ownerId}` } : null
    this.checklists = []

    return this.getFirst(url, params)
  }

  getNext(): Observable<Array<CheckList>> {
    return super.getNext().pipe(
      tap(checklists => {
      if (!this.checklists) {
        this.checklists = checklists
      } else {

        this.checklists.push(...checklists)
      }
    }))
  }

  getCheckList(id: number): Observable<CheckList> {
    let checkFound: CheckList = <CheckList>{}
    checkFound = this.checklists.find(checklists => checklists.id === id)
    if (checkFound) {
      return of(checkFound)
    } else {
    const url = `${this.baseURL}/api/checklists/${id}/`
    return this.http.get<CheckList>(url)

  }
}

  createOrModifyChecklist(checklist: Partial<CheckList>): Observable<CheckList> {
    let url: string
    if (checklist.id) {
      // console.log('request = ' + JSON.stringify(checklist))
      url = `${this.baseURL}/api/checklists/${checklist.id}/`
      return this.http.patch<CheckList>(url, checklist).pipe(
        tap(newChecklist => {
          const index = this.checklists.findIndex(x => x.id === checklist.id)
          if (index > -1) {
            this.checklists.splice(index, 1, newChecklist)
          } else {
            this.checklists.push(newChecklist)
          }
          // this.checklist$.next(this.checklists)
        })
      )
    } else {
      url = `${this.baseURL}/api/checklists/`
      return this.http.post<CheckList>(url, checklist).pipe(
        tap(newChecklist => {
          this.checklists.push(newChecklist)
          // this.checklist$.next(this.checklists)
        })
      )
    }
  }

  copyChecklist(id: number, ownerId: number): Observable<void> {
    const url = `${this.baseURL}/api/checklists/${id}/copy/?owner_id=${ownerId}`
    return this.http.post<void>(url, null).pipe(
      tap(_ => {
        this.setIsModified(true)
      })
    )
  }

  deleteChecklist(id: number): Observable<void> {
    let url: string
    if (id) {
      // console.log("request = " + JSON.stringify())
      url = `${this.baseURL}/api/checklists/${id}/`
      return this.http.delete<void>(url).pipe(
        tap(() => {
          const index = this.checklists.findIndex(x =>
            x.id === id)
          if (index > -1) {
            this.checklists.splice(index, 1)
            // this.checklist$.next(this.checklists)
          }

        })
      )
    }
  }


}
